@font-face {
  font-family: 'DM Mono';
  src: url('/fonts/DMMono-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Espiritu Dingbats';
  src: url('/fonts/Espiritu-Dingbats.woff2') format('woff2'),
    url('/fonts/Espiritu-Dingbats.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Espiritu Condensed';
  src: url('/fonts/Espiritu-Condensed.woff2') format('woff2'),
    url('/fonts/Espiritu-Condensed.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Espiritu Script';
  src: url('/fonts/Espiritu-Script.woff2') format('woff2'),
    url('/fonts/Espiritu-Script.woff') format('woff');
  font-weight: normal;
  font-display: block;
}

@font-face {
  font-family: 'Espiritu Regular';
  src: url('/fonts/Espiritu-Regular.woff2') format('woff2'),
    url('/fonts/Espiritu-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Espiritu Expanded';
  src: url('/fonts/Espiritu-Expanded.woff2') format('woff2'),
    url('/fonts/Espiritu-Expanded.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
