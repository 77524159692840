[data-sr-convertkit-subscribe-form] {
  @apply relative;
  [data-sr-button] {
    @apply mt-4 w-full inline-flex self-center justify-center items-center rounded-xl focus-visible:ring-2 focus-visible:ring-badass-blue focus-visible:ring-offset-4 focus-visible:ring-offset-badass-neutral-500 focus-visible:outline-none duration-150 font-sans h-[4.5rem] px-6 text-xl font-bold bg-badass-pink-600 hover:bg-badass-pink-800 disabled:bg-badass-pink-600 disabled:opacity-60 disabled:cursor-not-allowed disabled:text-black;
    span:first-child,
    span:last-child {
      display: none;
    }
  }
  [data-sr-fieldset] {
    @apply relative pb-7;
    label {
      @apply block font-mono uppercase text-base leading-none;
    }
    input {
      @apply block rounded-xl bg-transparent border border-gray-300 mt-2 h-16 w-full px-5 text-xl font-medium text-white focus-visible:outline-none ring-0;
      &[data-input-with-error='true'] {
        @apply border-badass-red-500;
      }
    }
    input::placeholder {
      @apply text-white opacity-70;
    }
  }
  [data-input-error-message] {
    @apply absolute left-0 bottom-1 text-right w-full text-sm font-mono text-badass-red-500;
  }
}
