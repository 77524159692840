@import './icons.css';
@import '~@skillrecordings/player/dist/skillrecordings-player.css';
@import '~@reach/dialog/styles.css';
@import './fonts.css';
@import './mdx-components.css';
@import './subscribe-form.css';
@import './card.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: dark;
  @apply selection:bg-badass-pink-500 selection:text-black;
}

html {
  scroll-behavior: smooth;
}

body {
  @apply antialiased bg-black text-white font-sans;
}

/*
This will hide the focus indicator if the element receives focus via the mouse,
but it will still show up on keyboard focus.
*/
[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  outline: none;
}

.twitter-tweet {
  border-radius: 13px;
  @apply overflow-hidden;
}
