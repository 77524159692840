/* https://css.gg/ */

:root {
  --ggs: 1;
}

.gg-play-button {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}

.gg-play-button::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 10px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  top: 6px;
  left: 9px;
}

.gg-chevron-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-chevron-down::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

.gg-chevron-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-chevron-up::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  left: 4px;
  bottom: 2px;
}

.gg-check {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-check::after {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: currentColor;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.gg-trophy {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 10px;
  height: 12px;
  border: 2px solid;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.gg-trophy::after,
.gg-trophy::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
}

.gg-trophy::before {
  box-shadow: inset 0 0 0 2px, 0 2px 0 0;
  bottom: -4px;
  width: 6px;
  height: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  left: 0;
}

.gg-trophy::after {
  width: 16px;
  height: 6px;
  border-left: 2px solid;
  border-right: 2px solid;
  left: -5px;
  top: 0;
}
